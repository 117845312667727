import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`What is an App?`}</h2>
    <p>{`An app (short for application) is a software program designed to perform specific tasks or functions on a device, such as a smartphone, tablet, computer, or wearable. Apps can be standalone or part of a larger system, offering a user interface for interacting with tools, services, or content.`}</p>
    <h2>{`CoreMedia Apps`}</h2>
    <p>{`As modules (that provide context) or functionalities, CoreMedia APPS allow to:`}</p>
    <ul>
      <li parentName="ul">{`Use data from CoreMedia service;`}</li>
      <li parentName="ul">{`Create new views and data reports;`}</li>
      <li parentName="ul">{`Extend segmentation rules;`}</li>
      <li parentName="ul">{`Provide new widgets.`}</li>
    </ul>
    <p>{`The motivation is to add functionality, in an agile way, without touching the platform core.`}</p>
    <h3>{`Call Tracking`}</h3>
    <p>{`Call Tracking allow us to track inbound calls with the same data we gather in Click2Call and to identify traffic origins and visitors.`}</p>
    <p>{`By presenting a different Inbound number to each visitor, we can collect the visitor's information every time a call is made to that specific number.`}</p>
    <h3>{`Filesharing`}</h3>
    <p>{`Allows to send and track a link to download files.Using this app we give the possibility to share documents during a Click2Call or a Live Chat using the File Transfer functionality.`}</p>
    <h3>{`CoBrowsing`}</h3>
    <p>{`CoBrowsing (short for collaborative browsing), in the context of web browsing, is the joint navigation by two or more people accessing the same web page at the same time. It should be used for customer support and sales assistance.
Functionality application:`}</p>
    <ul>
      <li parentName="ul">{`Click2Call and Live Chat`}</li>
      <li parentName="ul">{`Uni or bidirectional way`}</li>
      <li parentName="ul">{`Add pointers`}</li>
    </ul>
    <h3>{`Google ADS`}</h3>
    <p>{`Imagine the internet as a huge library, and Google as the librarian who assists you in locating the specific book you're searching for. Google Ads functions like a brightly colored bookmark that highlights the books most relevant to your business. `}</p>
    <p>{`Consequently, whenever someone seeks out that book, your bookmark will stand out prominently among the initial search results.`}</p>
    <p><strong parentName="p">{`How does it work?`}</strong></p>
    <p><em parentName="p">{`Keywords`}</em>{`: These are the words or phrases (keywords) that you select to describe your product or service. For instance, if you're selling shoes, your keywords could include "buy shoes," "athletic sneakers," or "women's sandals."`}</p>
    <p><em parentName="p">{`Ads`}</em>{`: We develop brief, attention-grabbing advertisements that are displayed when users search for these keywords.`}</p>
    <p><em parentName="p">{`Bids`}</em>{`: You specify the maximum amount you are willing to pay per click on your ad.`}</p>
    <p><em parentName="p">{`Landing Pages`}</em>{`: These are focused pages on your website (landing pages) designed to convert visitors into customers. After clicking your ad, users are directed to a page tailored to encourage a specific action, such as making a purchase or signing up for a newsletter.`}</p>
    <p><strong parentName="p">{`Why use Google Ads?`}</strong></p>
    <p>{`Reach a specific audience: Show your ads to people interested in your products or services.
Generate more website traffic: Increase the number of visitors to your site.
Boost sales: Convert visitors into customers.
Measure your results: Use Google Ads tools to track your campaign performance and make adjustments.`}</p>
    <p>{`With Google APP we are able to:`}</p>
    <ul>
      <li parentName="ul">{`Identify visitors from Google Ads campaigns;`}</li>
      <li parentName="ul">{`Send conversion events back.`}</li>
    </ul>
    <p>{`Advantages:`}</p>
    <ul>
      <li parentName="ul">{`Close de loop between platforms and even offline conversions, such as Click2Call sale conversions;`}</li>
      <li parentName="ul">{`Add valuable information to the campaign manager;`}</li>
      <li parentName="ul">{`Enable paid media optimization.`}</li>
    </ul>
    <h3>{`E-commerce`}</h3>
    <p>{`E-commerce APP creates the context of:`}</p>
    <ul>
      <li parentName="ul">{`shopping cart`}</li>
      <li parentName="ul">{`product catalog.`}</li>
    </ul>
    <p>{`The APP extends the CoreMedia service by:`}</p>
    <ul>
      <li parentName="ul">{`Adding shopping cart segmentation rules (e.g: total value amount in shopping cart, categories of products in shopping cart, etc);`}</li>
      <li parentName="ul">{`Product recommendations (w/ widget);`}</li>
      <li parentName="ul">{`Statistics and reporting (w/ backoffice panels)`}</li>
      <li parentName="ul">{`Shopping cart in ByCard.`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Term`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Context`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Refers to a logical grouping of sales that operates under specific rules and parameters.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Category`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Is an categorization of a product. Example: Vegatables are the category of lettuce.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Product`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Is the item that could be sell. A product could have: name, id, SKU, stock, value, details, color, photos,...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Shopping cart`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Basket that allows customers to purchase items from your shop or store a list of the items they wish to purchase in the future.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`SKU`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"Stock keeping unit" is a number that retailers use to differentiate products and track inventory levels. An SKU is typically eight alphanumeric digits long. Products are assigned different SKU numbers based on various characteristics, such as price, manufacturer, color, style,type and size.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Cart persistence`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`eCommerce websites which retains customer's carts after you exit the website. For example, when you visit an eCommerce site and abandon your cart, when you return the contents of your basket will be waiting for you.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Funnel Steps`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The e-commerce purchase funnel is a visual representation of the buyer's journey.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Checkout`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Is the point in the shopping process where consumers finalize their purchases and pay a business for certain products or services.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Recommendations`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Are suggestions of items based on what a customer has previously browsed, purchased or shown interest in. BySide has recommendation based on visitor, on product, on categories and on shopping cart.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`eCommerce Events`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Events that should be configured on website to give visibility of visitor navigation. List of events available:`}<br />{`View product, Add to ShopingCart, Remove from shopping cart, Update ShoppingCart, Actual step, Checkout, Clear shopping cart.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Product related`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Are product that was related manually an eCommerceApp. Product related could be presented on eCommerceWidget to present product related with a specific product.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Predictive analytics`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enables e-commerce businesses to understand what products customers are looking for, helps in identifying popular and not-so-popular products and product categories. Predictive search capabilities that can be built-in to your analytics solution will allow e-commerce businesses to analyze their past click-through behaviors, shopping history, and product preferences, in real time.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Member Get Member`}</h3>
    <p>{`Member Get Member transforms customers into promoters, thus allowing the collection of
new leads, and rewards the recruiters through points and promo codes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      